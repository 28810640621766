<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :total-items="totalItems"
          :headers="headers"
          :items="mutations"
          :page="page"
          :search="search"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          title="Mutasi"
          subtitle="Halaman untuk mutasi siswa "
          add-btn="Tambah Data"
          @delete-item="confirmDestroy"
          @edit-item="showFormEdit"
          @add-item="showFormAdd"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Mutasi Siswa': 'Edit Mutasi Siswa'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template
        v-slot:body
      >
        <div v-if="dialog === 'add'">
          <v-autocomplete
            v-model="mutation.student_uuid"
            label="Nama Siswa"
            outlined
            dense
            :items="students"
            item-text="name"
            item-value="uuid"
            clearable
          >
          </v-autocomplete>
        </div>
        <div>
          <v-autocomplete
            v-model="mutation.status"
            label="Status"
            outlined
            :items="statuses"
            item-text="title"
            dense
            clearable
          >
          </v-autocomplete>
        </div>
        <div>
          <v-textarea
            v-model="mutation.reason"
            label="Alasan Mutasi"
            outlined
            dense
          >
          </v-textarea>
        </div>
        <div>
          <v-menu
            v-model="date_out"
            :close-on-content-click="true"
            nudge-right="0"
            nudge-top="20"
            max-width="100%"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="mutation.out_date"
                label="Tanggal Keluar"
                readonly
                outlined
                dense
                v-bind="attrs"
                clearable
                v-on="on"
                @click="autofillDateOut"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="mutation.out_date"
              :first-day-of-week="0"
              locale="id-id"
              color="primary"
              @input="entry_date = false"
            ></v-date-picker>
          </v-menu>
        </div>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Mutasi"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    ></ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { integerValidator, required } from '@core/utils/validation'

export default {
  name: 'StudentMutation',
  components: {
    MainCard,
    ModalDialog,
    ConfirmDialog,
    SkeletonLoaderTable,
    DataTablePagination,
  },
  data() {
    return {
      dialog: '',
      search: '',
      width: 600,
      page: 1,
      totalItems: 0,
      totalPages: 0,
      modalDialog: false,
      confirmDialog: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      validation: {
        required,
        integerValidator,
      },
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Nis', value: 'student.nis' },
        { text: 'Nama Siswa', value: 'student.full_name' },
        { text: 'Status', value: 'status' },
        { text: 'Alasan', value: 'reason' },
        { text: 'Tanggal Keluar', value: 'out_date' },
        { text: 'Actions', value: 'actions' },
      ],
      mutations: [],
      formValid: false,
      date_out: false,
      mutation: {
        student_uuid: '',
        status: '',
        reason: '',
        out_date: '',
      },
      service: 'mutation',
      school: [],
      statuses: [
        { index: 1, title: 'Mutasi' },
        { index: 2, title: 'Dikeluarkan' },
        { index: 3, title: 'Mengundurkan Diri' },
        { index: 4, title: 'Putus Sekolah' },
        { index: 5, title: 'Wafat' },
        { index: 6, title: 'Hilang' },
      ],
      school_uuid: '',
      students: [],
      filterQuery: {
        search: '',
      },
    }
  },
  watch: {
    mutation: {
      handler() {
        const valid = []
        const requiredField = ['student_uuid', 'status', 'reason', 'out_date']
        Object.entries(this.mutation).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listMutation(this.filterQuery)
      },
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.listMutation()
    this.getStudent()
  },
  methods: {
    resetForm() {
      this.mutation.student_uuid = ''
      this.mutation.status = ''
      this.mutation.reason = ''
      this.mutation.out_date = ''
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        this.school_uuid = el.uuid
        this.mutation.school_uuid = this.school_uuid
      })

      return true
    },
    async listMutation(params = {}) {
      await this.$services.ApiServices.list(this.service, {
        ...params,
        page: this.page,
        graduate: '0',
        school_uuid: this.school_uuid,
      }).then(
        ({ data }) => {
          this.mutations = data.data.map((mutation, index) => ({
            ...mutation,
            index: index + 1,
            out_date: this.getFormatDate(mutation.out_date),
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
    },
    async getStudent(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('student', {
        ...params,
        per_page: 'all',
        school_uuid: this.school_uuid,
      }).then(
        ({ data }) => {
          this.students = data.data
        },
        err => console.error(err),
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    showFormAdd() {
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
    },
    async add() {
      this.isLoadingButton = true
      await this.$services.ApiServices.add(this.service, this.mutation).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      await this.listMutation(this.filterQuery)
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.modalDialog = true
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.mutation = data.data
        this.mutation.school_uuid = data.data.school.uuid
        this.mutation.student_uuid = data.data.student.uuid
        this.mutation.out_date = data.data.out_date.slice(0, 10)
      })
    },
    async update() {
      this.isLoadingButton = true
      await this.$services.ApiServices.update(this.service, this.mutation, this.mutation.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listMutation(this.filterQuery)
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.mutation.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('mutation', this.mutation.uuid).then(
        ({ data }) => {
          this.listMutation(this.filterQuery)
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
    },
    async searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      await this.listMutation(this.filterQuery)
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    autofillDateOut() {
      this.mutation.out_date = new Date().toISOString().substr(0, 10)
    },
    getFormatDate(date) {
      const createdDate = new Date(date)
      const day = `0${createdDate.getDate()}`
      const month = `0${createdDate.getMonth() + 1}` // months are zero based
      const year = createdDate.getFullYear()

      return `${day.slice(-2)}/${month.slice(-2)}/${year}`
    },
  },
}
</script>

<style>
</style>
